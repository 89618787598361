<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="8"
              ><h1
                style="font-size : 32px; position : relative; top : 3px; margin-left : 20px"
              >
                구매 신청서 || 신청 아이디 : {{ this.$route.params.code }}
              </h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div style="margin : 150px 20px 0 20px">
        <v-expansion-panels focusable popout multiple v-model="panel">
          <v-expansion-panel v-for="(item, i) in prList" :key="i">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="item.type === 'String'">
              <span
                v-html="computedLine(item.value)"
                style="width : 90%; word-break: break-all;"
              ></span>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="item.type === 'Img' && i !== 3">
              <img :src="item.value" style="width : 100%;" />
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="item.type === 'Img' && i === 3">
              <img
                :src="item.value"
                style="width : 100%;"
                v-if="item.value === ''"
              />
              <span v-if="item.value === ''">사진 없음</span>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="item.type === 'list' && i === 3">
              <img
                v-for="(data, i) in item.value"
                :key="i + 'A'"
                :src="'https://www.drrefer.net/api/picApply/' + data.fileId"
                style="width : 100%; margin : 5px"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      mapLocation: {
        posX: 37.578801,
        posY: 126.966441,
      },
      prList: [
        { title: "의사 이름", value: "", type: "String" },
        { title: "의사 프로필 사진", value: "", type: "Img" },
        { title: "병원 로고", value: "", type: "Img" },
        { title: "병원 사진", value: "", type: "Img" },
        { title: "병원 약도", value: "", type: "Img" },
        { title: "병원 이름", value: "", type: "String" },
        { title: "병원 소개", value: "", type: "String" },
        { title: "병원 소개 상세", value: "", type: "String" },
        { title: "병원 주소", value: "", type: "String" },
        { title: "의사소개", value: "", type: "String" },
        { title: "진료내용", value: "", type: "String" },
        { title: "진단항목", value: "", type: "String" },
        { title: "전화번호", value: "", type: "String" },
        { title: "팩스번호", value: "", type: "String" },
        { title: "진료시간", value: "", type: "String" },
        { title: "홈페이지", value: "", type: "String" },
        { title: "기타 항목", value: "", type: "String" },
        { title: "추가 상세항목", value: "", type: "String" },
      ],
      pr: {
        logoAttchId: null,
        hospNm: null,
        hospIntro: null,
        conConts: null,
        drPicAttchId: null,
        drNm: null,
        drHist: null,
        doctIntro: null,
        addrDtl: null,
        hospTel: null,
        hospFaxNo: null,
        etcConts: null,
        mapAttchId: null,
        picAttchId: null,
        prAttchId: null,
        rsvUrl: null,
        homepage: null,
        drId: null,
        docAddvTitle: null,
        docAddvConts: null,
        etcPage: [
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
          {
            type: null,
            value: null,
          },
        ],
      },
    };
  },
  created() {
    this.getBasicData();
    this.getPromotionData();
    this.getApplyData();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
  },
  methods: {
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("prod", ["BRING_INPROGRESS_APPLY"]),
    async getApplyData() {
      const inqParam = {
        drId: localStorage.getItem("drId"),
      };

      const transData = new FormData();
      const transedInqParam = JSON.stringify(inqParam);
      transData.append("inqParam", transedInqParam);
      await this.BRING_INPROGRESS_APPLY(transData).then((data) => {
        //console.log(data.param);
        //console.log(data.list);
        if (data.param.doctIntro2 !== null && data.param.doctIntro2 !== "") {
          this.prList[9].value =
            "의사소개 1 :\n\n" +
            this.prList[9].value +
            "\n\n의사소개 2 :\n\n" +
            data.param.doctIntro2;
        }
        if (data.param.doctIntro3 !== null && data.param.doctIntro3 !== "") {
          this.prList[9].value =
            this.prList[9].value +
            "\n\n의사소개 3 :\n\n" +
            data.param.doctIntro3;
        }
        if (data.param.doctIntro4 !== null && data.param.doctIntro4 !== "") {
          this.prList[9].value =
            this.prList[9].value +
            "\n\n의사소개 4 :\n\n" +
            data.param.doctIntro4;
        }
        if (data.param.doctIntro5 !== null && data.param.doctIntro5 !== "") {
          this.prList[9].value =
            this.prList[9].value +
            "\n\n의사소개 5 :\n\n" +
            data.param.doctIntro5;
        }
        if (data.param.jinConts !== null && data.param.jinConts !== "") {
          this.prList[10].value = data.param.jinConts;
        }
        if (data.param.jinItems !== null && data.param.jinItems !== "") {
          this.prList[11].value = data.param.jinItems;
        }
        if (data.list.length !== 0) {
          this.prList[3].value = data.list;
          this.prList[3].type = "list";
        }

        //console.log(this.prList[10].value);
      });
    },
    async getBasicData() {
      try {
        await this.FETCH_BASIC_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            //console.log("찾기 회원정보", this.GET_BASIC);
            if (this.GET_BASIC.hospNm !== null) {
              this.prList[5].value = this.GET_BASIC.hospNm;
            }
            if (this.GET_BASIC.drNm !== null) {
              this.prList[0].value = this.GET_BASIC.drNm;
            }
            if (this.GET_BASIC.drId !== null) {
              this.pr.drId = this.GET_BASIC.drId;
            }
            if (this.GET_BASIC.addr !== null) {
              this.prList[8].value = this.GET_BASIC.addr;
            }
            if (this.GET_BASIC.addrDtl !== null) {
              this.pr.addrDtl = this.GET_BASIC.addrDtl;
            }
            if (this.GET_BASIC.posX !== null) {
              this.mapLocation.posX = this.GET_BASIC.posX;
            }
            if (this.GET_BASIC.posY !== null) {
              this.mapLocation.posY = this.GET_BASIC.posY;
            }
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getPromotionData() {
      /**
       *  삭제,변경 => 1
       *  유지,입력 => 0
       *
       */
      try {
        await this.FETCH_PROMOTION_TARGET_UN_AUTH(this.$route.params.code).then(
          () => {
            //console.log("찾기 조회정보", this.GET_PROMOTION);
            if (this.GET_PROMOTION.logoAttchId !== null) {
              this.prList[2].value =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
            }
            if (this.GET_PROMOTION.hospIntro !== null) {
              this.prList[6].value = this.GET_PROMOTION.hospIntro;
            }
            if (this.GET_PROMOTION.conConts !== null) {
              this.prList[7].value = this.GET_PROMOTION.conConts;
            }
            if (this.GET_PROMOTION.drPicAttchId !== null) {
              this.prList[1].value =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
            }
            if (this.GET_PROMOTION.doctIntro !== null) {
              this.prList[9].value = this.GET_PROMOTION.doctIntro;
            }
            if (this.GET_PROMOTION.hospTel !== null) {
              this.prList[12].value = this.phoneFomatter(
                this.GET_PROMOTION.hospTel
              );
            }
            if (this.GET_PROMOTION.hospFaxNo !== null) {
              this.prList[13].value = this.phoneFomatter(
                this.GET_PROMOTION.hospFaxNo
              );
            }
            if (this.GET_PROMOTION.etcConts !== null) {
              this.prList[14].value = this.GET_PROMOTION.etcConts;
            }
            if (this.GET_PROMOTION.mapAttchId !== null) {
              this.prList[4].value =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
            }
            if (
              this.GET_PROMOTION.picAttchId !== null &&
              this.GET_PROMOTION.picAttchId !== ""
            ) {
              this.prList[3].value =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
            }
            if (this.GET_PROMOTION.prAttchId !== null) {
              this.pr.prAttchId =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
            }
            if (this.GET_PROMOTION.rsvUrl !== null) {
              this.pr.rsvUrl = this.GET_PROMOTION.rsvUrl;
            }
            if (this.GET_PROMOTION.homepage !== null) {
              this.prList[15].value = this.GET_PROMOTION.homepage;
            }
            if (
              this.GET_PROMOTION.url1 !== null &&
              this.GET_PROMOTION.url1 !== ""
            ) {
              this.pr.etcPage[0].value = this.GET_PROMOTION.url1;
            }
            if (
              this.GET_PROMOTION.urlNm1 !== null &&
              this.GET_PROMOTION.urlNm1 !== "" &&
              this.GET_PROMOTION.urlNm1 !== "선택안함"
            ) {
              this.pr.etcPage[0].type = this.GET_PROMOTION.urlNm1;
            }
            if (
              this.GET_PROMOTION.url2 !== null &&
              this.GET_PROMOTION.url2 !== ""
            ) {
              this.pr.etcPage[1].value = this.GET_PROMOTION.url2;
            }
            if (
              this.GET_PROMOTION.urlNm2 !== null &&
              this.GET_PROMOTION.urlNm2 !== "" &&
              this.GET_PROMOTION.urlNm2 !== "선택안함"
            ) {
              this.pr.etcPage[1].type = this.GET_PROMOTION.urlNm2;
            }
            if (
              this.GET_PROMOTION.url3 !== null &&
              this.GET_PROMOTION.url3 !== ""
            ) {
              this.pr.etcPage[2].value = this.GET_PROMOTION.url3;
            }
            if (
              this.GET_PROMOTION.urlNm3 !== null &&
              this.GET_PROMOTION.urlNm3 !== "" &&
              this.GET_PROMOTION.urlNm3 !== "선택안함"
            ) {
              this.pr.etcPage[2].type = this.GET_PROMOTION.urlNm3;
            }

            this.prList[16].value =
              "기타항목1 : " +
              (this.pr.etcPage[0].value === null
                ? "없음"
                : this.pr.etcPage[0].value) +
              "\n기타항목2 : " +
              (this.pr.etcPage[1].value === null
                ? "없음"
                : this.pr.etcPage[1].value) +
              "\n기타항목3 : " +
              (this.pr.etcPage[2].value === null
                ? "없음"
                : this.pr.etcPage[2].value);
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.pr.docAddvTitle = this.GET_PROMOTION.docAddvTitle;
            }

            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.prList[17].value = this.GET_PROMOTION.docAddvTitle;
            }

            if (
              this.GET_PROMOTION.docAddvConts !== null &&
              this.GET_PROMOTION.docAddvConts !== ""
            ) {
              this.prList[17].value =
                this.prList[17].value +
                "\n\n" +
                this.GET_PROMOTION.docAddvConts;
            }
          }
        );
      } catch (error) {
        console.log("error:", error);
      }
    },
    phoneFomatter(number) {
      //console.log("정보", number);
      //console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        //console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style></style>
